import React from "react"
import "./Footer.scss";
import { Container } from "react-bootstrap";

const Footer = () => {
    return (
        <footer className="main-footer">
            <Container>
                <div className="link-panel">
                    <div className="link-item">
                        <div className="link-image logo-minpros"></div>
                        <a className="btn-arrow" href="https://edu.gov.ru/">
                            <i className="text-icon arrow-right-light-2" />
                            {`Перейти на сайт`}
                        </a>
                    </div>
                    <div className="link-item">
                        <div className="link-image logo-cms"></div>
                        <a className="btn-arrow" href="https://cic-edu.ru/">
                            <i className="text-icon arrow-right-light-2" />
                            {`Перейти на сайт`}
                        </a>
                    </div>
                    <div className="link-item">
                        <div className="link-image logo-arsa"></div>
                        <a className="btn-arrow" href="https://t.me/AssociationSchool">
                            <i className="text-icon arrow-right-light-2" />
                            {`Перейти на сайт`}
                        </a>
                    </div>
                </div>
                <hr/>
                <div className="down-string">
                    <div className="copyright">
                        {`© 2023 - ${new Date().getFullYear()}, ФГБОУ «Центр международного сотрудничества Министерства просвещения Российской Федерации»`}
                    </div>
                </div>
            </Container>
        </footer>
    )
}

export default Footer