import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./Header.scss";

const Header = () => {
    return (
        <header className="main-header">
            <h1>
                Лучшая русская школа за рубежом
            </h1>
            <div className="header-bg">
                <div className="header-bird"></div>
                <Container>
                    <Row>
                        <Col></Col>
                        <Col>
                            <div className="header-logos">
                                <a href="https://edu.gov.ru/" className="logo-minpros"><div></div></a>
                                <a href="https://cic-edu.ru/" className="logo-cms"><div></div></a>
                                <a href="https://t.me/AssociationSchool" className="logo-arsa"><div></div></a>
                            </div>
                        </Col>
                    </Row>
                    <div className="title-img">

                    </div>
                </Container>
            </div>
        </header>
    )
}

export default Header