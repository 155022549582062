import React from "react";
import "./ItemCard.scss";

const ItemCard = (props) => {
    const { title, author, tabTitle, folder, file } = props;

    return (
        <div className="item-card">
            <div className="item-title">
                {title}
            </div>
            <div className="down-part">
                <div className="author">
                    {author}
                </div>
                <div className="buttons-panel">
                    <div className="btn-arrow">
                        <i className="text-icon arrow-right-light" />
                        {tabTitle}
                    </div>
                    <a className="btn-download" href={`${window.location.origin}/docs/${folder}/${file}`}>
                        <i className="text-icon ico-download" />
                    </a>
                </div>
            </div>
        </div>
    )
}

export default ItemCard