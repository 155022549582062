import React, { useEffect, useState } from "react"
import { Container } from "react-bootstrap"
import "./MainPage.scss";
import ItemCard from "./ItemCard";
import pageInfo from '../data/data.json'

const MainPage = () => {

    const data = pageInfo.docs

    const [currentTabIndex, setCurrentTabIndex] = useState(0)

    return (
        <div className="main-page">
            <Container>
                <div className="text-block-1">
                    {`IV Международный конкурс «Лучшая русская школа за рубежом» `}
                    {`в 2024 году`}
                    {` объединил `}
                    <span className="span-block span-blue">
                        <i className="text-icon plus-light" />
                        {`более 1500 учителей`}
                    </span>
                    {` из `}
                    <span className="span-block span-white">
                        <i className="text-icon arrow-right-light" />
                        {`46 стран.`}
                    </span>
                    {` `}
                    {`Директора и учителя представили свои уникальные `}
                    <span className="span-block span-white">
                        <i className="text-icon arrow-right-light" />
                        <i className="text-icon bird-dark" />
                        {`методические разработки`}
                    </span>
                    {` `}
                    <span>
                        <i className="text-icon plus-light-2" />
                        {`технологии развития `}
                        <i className="text-icon plus-dark" />
                    </span>
                    <span>
                        <i className="text-icon arrow-right-dark" />
                        <i className="text-icon arrow-right-dark" />
                        <i className="text-icon arrow-down-dark" />
                        <i className="text-icon arrow-down-dark" />
                    </span>
                    {`школьного образования и `}
                    <span className="span-block span-blue">
                        <i className="text-icon arrow-right-light-2" />
                        {`русскому языку `}
                        <i className="text-icon plus-light" />
                    </span>
                    {` и предметов `}
                    {`на русском языке `}
                    <span>
                        <i className="text-icon arrow-right-dark" />
                        <i className="text-icon arrow-right-dark" />
                        <i className="text-icon bird-light" />
                        <i className="text-icon arrow-left-dark" />
                        <i className="text-icon arrow-left-dark" />
                    </span>
                </div>
            </Container>
            <div className="text-block-2">
                <Container>
                    {`Этот уникальный материал необходимо сохранить, чтобы им могли пользоваться учителя всего мира. Поэтому мы создали единый банк методик, где собраны разработки и предложения, идеи и инициативы учителей, преподающих в самых разных странах.`}
                </Container>
            </div>
            <Container>
                <div className="title">
                    <i className="text-icon arrow-right-light-big arrow-big" />
                    {`Библиотека методических наработок педагогов русских школ за рубежом`}
                </div>

                <div className="tab-switcher">
                    {data.map((item, index) => {
                        return <div
                            className={`tab-switcher-item ${currentTabIndex === index ? 'item-active' : 'item-unactive'}`}
                            key={index}
                            onClick={(e) => setCurrentTabIndex(index)}
                        >
                            {currentTabIndex === index ? (
                                <i className="text-icon arrow-right-light-2" />
                            ) : (
                                <i className="text-icon arrow-right-light" />
                            )
                            }
                            {item['title']}
                        </div>
                    })}
                </div>

                <div className="items-list">
                    {data[currentTabIndex]["items"].map((item, index) => {
                        return <ItemCard
                            key={index}
                            title={item["title"]}
                            author={item["author"]}
                            tabTitle={data[currentTabIndex]["title"]}
                            folder={`tab${currentTabIndex}`}
                            file={item["file"]}
                        />
                    })}
                </div>
            </Container>

            <div className="text-block-3">
                <Container>
                    <div className="txt-big">
                        <span>
                            <i className="text-icon arrow-right-white" />
                            <i className="text-icon arrow-right-white" />
                            <i className="text-icon arrow-right-white" />
                        </span>
                        {` Пусть это собрание послужит вам ориентиром и источником вдохновения! `}
                        <span>
                            <i className="text-icon arrow-left-white" />
                            <i className="text-icon arrow-left-white" />
                            <i className="text-icon arrow-left-white" />
                        </span>
                    </div>
                    <hr />
                    <div className="txt-small">
                        {`Мы надеемся, что оно поможет обогатить вашу ежедневную практику новыми идеями. А возможно, в следующем году вы и сами предложите на суд жюри конкурса собственные работы, и ваша школа займет достойное место в числе ЛУЧШИХ РУССКИХ ШКОЛ ЗА РУБЕЖОМ!`}
                    </div>
                </Container>
            </div>
        </div>
    )
}

export default MainPage